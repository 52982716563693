.headerBox {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #E06F41;
}

.navbar-expand-md{
  text-align: center !important;
}

.frame-19 {
  align-items: center;
  background-color: #0b0e24;
  border: 2px solid transparent;
  border-image: linear-gradient(180deg,#252f47 21.35%,rgba(37,47,71,.32)) 1;
  /*border-radius: 96px;
  display: flex;*/
  gap: 10px;
  height: 60px;
  justify-content: center;
  padding: 15px 15px;
  position: relative;
  width: 60px;
}

.slick-dots{
  bottom: -30px !important;
}


.rectangle {
  background: linear-gradient(180deg,#4ba5eb,rgba(75,165,235,0));
  height: 1px;
  position: relative;
  width: auto;
}

.bracketscurly-1-wrapper {
  background-image: url(/src/assets/icons/ellipse-27.9d84611122b68dae0d54.svg);
  background-size: 100% 100%;
  height: 60px;
  position: relative;
  width: 60px;
}

.icon-instance-node-4 {
  height: 24px!important;
  left: 18px!important;
  position: absolute!important;
  top: 18px!important;
  width: 24px!important;
}

input[type="text"]{
  color: #4AA2E7 !important;
}
